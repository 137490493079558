// QA URLs
// const superadminAPI = "https://qa-superadmin-api.clideanalyser.in";
// const mainUrl = "https://dev-api.clideanalyser.in";
//localhost
// const mainUrl = "http://localhost:8080/ClideApp";

//Production Urlgi
 const mainUrl = "https://clide-api.clideanalyser.in";
 const superadminAPI = "https://superadmin-api.clideanalyser.in";

//Production Urlgi
// const mainUrl = "https://clide-api.clideanalyser.in";
// const superadminAPI = "https://superadmin-api.clideanalyser.in";

// const constURL = "https://qa-superadmin-api.clideoffice.com/myresource/";


//britannia env urls
// const mainUrl = "https://clide-api.britindia.com";
// const superadminAPI = "https://clidedb.britindia.com";

const urls = {
  clientURL: mainUrl,
  login: `${mainUrl}/user/login`,
  changePassword: `${mainUrl}/user/changepassword`,
  forgotPassword: `${mainUrl}/user/forgetpassword`,
  usersList: `${mainUrl}/user/details`,
  userSearch: `${mainUrl}/master/usersearch`,
  externalUserSearch: `${mainUrl}/master/externalusersearch`,
  setPassword: `${mainUrl}/user/setpassword`,
  linkExpire: `${mainUrl}/user/linkexpirecheck`,
  resendLink: `${mainUrl}/user/resendlink`,
  profile: `${mainUrl}/user/profile`,
  designation: `${mainUrl}/user/userdesignationlist`,
  compnayLogo: `${mainUrl}/user/companylogo`,
  hTriangle: `${mainUrl}/htriangle/list`,
  notification: `${mainUrl}/notification`,
  notificationNo: `${mainUrl}/notification/notificationNumber`,
  notificationRead: `${mainUrl}/notification/notificationStatus`,
  communicationNotification: `${superadminAPI}/recentUpdate/getpublishrecentUpdate`,
  loginPageContent: `${superadminAPI}/index/viewindexgif`,
  tagLine: `${superadminAPI}/index/tagline`,
  editprofile: `${mainUrl}/user/editprofile`,
  safetyChampion: `${mainUrl}/sm/safetychampian`,
  safetyChampionFilter: `${mainUrl}/sm/filtersafetychampian`,
  userwisedata: `${mainUrl}/user/userwisedata`,
  userwisedataexcel: `${mainUrl}/user/userwisedataexcel`,
  allModulesCount: {
    lastTwoDaysCount: `${mainUrl}/allmodulescount/lasttwodayscount`,
    monthwisecount: `${mainUrl}/allmodulescount/monthwisecount`,
    openNumbers: `${mainUrl}/allmodulescount/opennumbers`,
    numbers: `${mainUrl}/allmodulescount/numbers`,
    target: `${mainUrl}/soidashboard/bscannualtarget`,
  },
  adminResendForgotPasswordMail:`${mainUrl}/adminservice/resendemailforpassword`,
  adminprofile:`${mainUrl}/adminprofile/adminprofiledetails`,
  editAdminProfile:`${mainUrl}/adminprofile/editadminprofile`,
  adminChangePassword:`${mainUrl}/adminprofile/adminchangepassword`,

  bscReport: {
    bscReportRight: `${mainUrl}/bscreport/bscmodulerights`,
    serviceBasedUserList: `${mainUrl}/bscreport/bscuserrightlist`,
    assignUser: `${mainUrl}/bscreport/addbscuserrights`,
    removeUser: `${mainUrl}/bscreport/deletebscuserrights`,
    bsc: {
      yearList: `${mainUrl}/bscreport/bscreportyearlist`,
      misListing: `${mainUrl}/bscreport/listbscmiscount`,
      leadingListing: `${mainUrl}/bscreport/listallmonthbscreportleadcount`,
      leadingGraphListing: `${mainUrl}/bscreport/bscleadingIndicatorGraphCounts`,
      laggingListing: `${mainUrl}/bscreport/listallmonthbscreportlaggingcount`,
      laggingGraphListing: `${mainUrl}/bscreport/bsclaggingIndicatorGraphCounts`,
      monthList: `${mainUrl}/bscreport/bsctabmonthoptionlist`,
      overallMonthList: `${mainUrl}/bscreport/overallgraphfilterlist`,
      overallGraphList: `${mainUrl}/bscreport/bscleadlagoverallgraphcounts`,
      monthleadingListing: `${mainUrl}/bscreport/bscleadcountmonthwisedetails`,
      monthlaggingListing: `${mainUrl}/bscreport/bsclagcountmonthwisedetails`,
    },
    kpi: {
      yearList: `${mainUrl}/bscreport/kpiyearlist`,
      misListing: `${mainUrl}/bscreport/listkpimiscount`,
      leadingListing: `${mainUrl}/bscreport/listkpileadingindicator`,
      laggingListing: `${mainUrl}/bscreport/listkpilaggingindicator`,
    },
  },
  mis: {
    yearsFilterList: `${mainUrl}/misreport/yearfilteroptionlist`,
    serviceBasedUserList: `${mainUrl}/misreport/misuserrightlist`,
    assignUser: `${mainUrl}/misreport/addmisuserrights`,
    removeUser: `${mainUrl}/misreport/deletemisuserrights`,
    admin: {
      workflow: {
        misFormConfigurationEdit: `${mainUrl}/misadminvalues/misuseralloweddurationedit`,
        misFormConfigurationList: `${mainUrl}/misadminvalues/misuseralloweddurationlist`,
      },
    },
    manpowerTab: {
      averageManpowerTraferList: `${mainUrl}/misreport/listaveragemanpowercount`,
      averageManpowerRefreshList: `${mainUrl}/misreport/refreshaveragemanpowercount`,
      manpowerList: `${mainUrl}/misreport/listmanpowercount`,
      manpowerEdit: `${mainUrl}/misreport/editmanpowercount`,
      manhoursList: `${mainUrl}/misreport/listmanhours`,
      manhourEdit: `${mainUrl}/misreport/editmanhours`,
      equpimentList: `${mainUrl}/misreport/listequipmentareacount`,
      equpimentEdit: `${mainUrl}/misreport/editequipmentareacount`,
    },
    bscLeadingTab: {
      yearsFilterList: `${mainUrl}/misreport/leadingyearoptionlist`,
      actionList: `${mainUrl}/misreport/listleadingactioncount`,
      actionEdit: `${mainUrl}/misreport/editleadingactioncount`,
      appreciationList: `${mainUrl}/misreport/listleadingappreciationscount`,
      appreciationEdit: `${mainUrl}/misreport/editleadingappreciationscount`,
      awardsList: `${mainUrl}/misreport/listleadingawardscount`,
      awardsEdit: `${mainUrl}/misreport/editleadingawardscount`,
      eventsList: `${mainUrl}/misreport/listleadingeventscount`,
      eventsEdit: `${mainUrl}/misreport/editleadingeventscount`,
      kaizenList: `${mainUrl}/misreport/listleadingkaizencount`,
      kaizenEdit: `${mainUrl}/misreport/editleadingkaizencount`,
      trainingList: `${mainUrl}/misreport/listleadingtrainingcount`,
      trainingEdit: `${mainUrl}/misreport/editleadingtrainingcount`,
      reviewList: `${mainUrl}/misreport/listleadingreviewcount`,
      reviewEdit: `${mainUrl}/misreport/editleadingreviewcount`,
      auditList: `${mainUrl}/misreport/listleadingauditcount`,
      auditEdit: `${mainUrl}/misreport/editleadingauditcount`,
      inspectionList: `${mainUrl}/misreport/listleadinginspectioncount`,
      inspectionEdit: `${mainUrl}/misreport/editleadinginspectioncount`,
      deviationList: `${mainUrl}/misreport/listleadingdeviationcount`,
      capaList: `${mainUrl}/misreport/listbscleadcapacount`,
      sourceList: `${mainUrl}/misreport/listleadingindicatorcount`,
    },
    bsLaggingTab: {
      yearsFilterList: `${mainUrl}/misreport/laggingyearoptionlist`,
      laggingList: `${mainUrl}/misreport/listlaggingindicatorcount`,
      laggingInputList: `${mainUrl}/misreport/listlaggingcount`,
      laggindEdit: `${mainUrl}/misreport/editlaggingcount`,
    },
    analysis: {
      parameterList: `${mainUrl}/misreport/listmisanalysis`,
      parameterRefreshList: `${mainUrl}/misreport/refreshmisanalysiscount`,
      yearsFilterList: `${mainUrl}/misreport/misanalysisyearoptionlist`,
    },
  },
  smDashboard: {
    reportFeeds: `${mainUrl}/sm/dashboardcontent`,
    resolveSOI: `${mainUrl}/sm/dashboardcontentresolvesoi`,
    removeInviteUser: `${mainUrl}/sm/dashboarddetailsoiremoveinvitee`,
    addInviteUser: `${mainUrl}/sm/dashboarddetailsoiaddinvitee`,
    inviteUserList: `${mainUrl}/sm/dashboarddetailinviteeuserlist`,
    otherDashboardDetails: `${mainUrl}/sm/dashboardHeader`,
    addSafetyThought: `${mainUrl}/sm/dashboardcontentaddsafetythought`,
    editSafetyThought: `${mainUrl}/sm/editSafetyThought `,
    deleteSafetyThought: `${mainUrl}/sm/dashboardcontentremovesafetythought`,
    likeReport: `${mainUrl}/sm/dashboardcontentaddlike`,
    removeLikeReport: `${mainUrl}/sm/dashboardcontentremovelike`,
    socialMediaFilter: `${mainUrl}/sm/dashboardcontentmodulefilter`,
    socialMediaFilterPersonal: `${mainUrl}/sm/dashboardcontentmodulefilterpersonal`,
    socialMediaSafetyThoughtFilter: `${mainUrl}/sm/dashboardcontentstfilter`,
    getCommentsList: `${mainUrl}/sm/dashboarddetailcommentlist`,
    addMainComment: `${mainUrl}/sm/dashboardcontentaddcomment`,
    replyToComment: `${mainUrl}/sm/dashboardcontentaddsubcomment`,
    addLikeToComment: `${mainUrl}/sm/dashboardcontentaddliketocomment`,
    dashboarddetailcommentsubcommentlist: `${mainUrl}/sm/dashboarddetailcommentsubcommentlist`,
    dashboardcontentcommentremoveunlike: `${mainUrl}/sm/dashboardcontentcommentremoveunlike`,
    dashboarddetaillikelist: `${mainUrl}/sm/dashboarddetaillikelist`,
  },
  soiDashboard: {
    openResolveNumber: `${mainUrl}/statsoi/soinumber`,
    openList: `${mainUrl}/listSoi/soiopenlistweb`,
    resolveList: `${mainUrl}/listSoi/soiresolvelistweb`,
    openListProject: `${mainUrl}/listSoi/soiopenlistwebproject`,
    // openListProject: `${mainUrl}/listSoi/soiopenlistwebprojectnew`,
    resolveListProject: `${mainUrl}/listSoi/soiresolvelistwebproject`,
    // resolveListProject: `${mainUrl}/listSoi/soiresolvelistwebprojectnew`,
    openDetailList: `${mainUrl}/listSoi/soiopendetail`,
    resolveDetailList: `${mainUrl}/listSoi/soiresolvedetail`,
    soiOpenTransferList: `${mainUrl}/listSoi/soiopentransferlistweb`,
    serviceProjectList: `${mainUrl}/service/serviceprojectlist`,
    dashboardNumbers: `${mainUrl}/statsoi/soidashboardnumber`,
    dashboard: {
      stats: `${mainUrl}/soidashboard/stat`,
      trendsGraphs: `${mainUrl}/soidashboard/trends`,
      statProject: `${mainUrl}/soidashboard/statproject`,
      dashboardNumber: `${mainUrl}/statsoi/soidashboardnumberproject`,
      zoneRights: `${mainUrl}/soidashboard/zonerights`,
      uaucGraphs: `${mainUrl}/soidashboard/uaanducseperategraphproject`,
    },
    escalationList: `${mainUrl}/project/soiescalationlist`,
    closingRights: `${mainUrl}/project/soiclosingrightlist`,
    soiSearch: `${mainUrl}/listSoi/soiopensearch`,
    soiResolveSearch: `${mainUrl}/listSoi/soiresolvesearch`,
    soideletecomment: `${mainUrl}/listSoi/soideletecomment`,
    soiopendelete: `${mainUrl}/listSoi/soiopendelete`,
    openEdit: `${mainUrl}/listSoi/soiedit`,
    soiOpenOpenSearchResolve: `${mainUrl}/listSoi/soiopensearchforresolve`,
    soiexceloverall: `${mainUrl}/listSoi/soiexceloverall`,
    soiexceloverallresolve: `${mainUrl}/listSoi/soiexceloverallresolve`,
    escalatedList: `${mainUrl}/listSoi/escalatedlist`,
    deleteControlMeasure: `${mainUrl}/listSoi/deletesoicontrolmeasure`,
    soiOpenListDateSort: `${mainUrl}/listSoi/soiopenlistdatesort`,
    soiDashboardAnalysis: `${mainUrl}/statsoi/soidashboardanalysis`,
    soiDashboardAnalysisProject: `${mainUrl}/statsoi/soidashboardanalysisproject`,
  },
  summary: {
    summary: `${mainUrl}/summary/allsummary`,
    zoneList: `${mainUrl}/project/zonelist`,
    departmentList: `${mainUrl}/project/pdeptlist`,
    contractorList: `${mainUrl}/project/pconlist`,
    safetyTeamList: `${mainUrl}/project/psafetylist`,
    activityList: `${mainUrl}/project/pactivitylist`,
    barrierList: `${mainUrl}/project/pbarrierlist`,
    hazardList: `${mainUrl}/project/phazardlist`,
    formParameterList: `${mainUrl}/project/soiformparameterlist`,
    formParameterEdit: `${mainUrl}/project/soiformparameteredit`,
    userList: `${mainUrl}/project/userlist`,
    allmodulescount: `${mainUrl}/allmodulescount/monthwisecount/`,
    financialCosting: `${mainUrl}/summary/financialcosting`,
  },
  predictiveAnalysis: `${mainUrl}/summary/birdeyeview`,
  formType1: {
    createForm: `${mainUrl}/formtype1/createform`,
    editForm: `${mainUrl}/formtype1/editform`,
    formNumber: `${mainUrl}/formtype1/formcount`,
    deleteForm: `${mainUrl}/formtype1/deleteForm`,
    unPublishedlist: `${mainUrl}/formtype1/unpublishedlist`,
    publishedlist: `${mainUrl}/formtype1/publishedlist`,
    makeUnpublishedForm: `${mainUrl}/formtype1/makeUnpublishedForm`,
    assignLocation: `${mainUrl}/formtype1/assignlocation`,
    createformdetails: `${mainUrl}/formtype1/createformdetails`,
    userFormListing: `${mainUrl}/formtype1/form`,
    userCompletedFormListing: `${mainUrl}/formtype1/completedform`,
    userFormDetails: `${mainUrl}/formtype1/userformdetails`,
    sectionList: `${mainUrl}/formtype1/sectionList`,
    archiveList: `${mainUrl}/formtype1/archivelist`,
    makeUnarchieveForm: `${mainUrl}/formtype1/makeUnarchieveForm`,
    marketPlace: `${mainUrl}/formtype1/marketplace`,
    useMarketPlace: `${mainUrl}/formtype1/usemarketplace`,
    useDocumentFromMarketPlace: `${mainUrl}/formtype1/usedocumentfrommarketplace`,
    settingPage: `${mainUrl}/formtype1/settingpage`,
    setting: `${mainUrl}/formtype1/setting`,
    escalationList: `${mainUrl}/formtype1/escalationlist`,
    moduleDashboard: `${mainUrl}/formtype1/moduledashboard`,
    unlockRequestList: `${mainUrl}/formtype1/unlockrequestlist`,
    personalUnlockRequestList: `${mainUrl}/formtype1/personalunlockrequest`,
    unlockForm: `${mainUrl}/formtype1/unlockform`,
    unlockRequestUser: `${mainUrl}/formtype1/unlockrequestuser`,
    userResponse: `${mainUrl}/formtype1/userresponse`,
    assignUser: `${mainUrl}/formtype1/assignuser`,
    assignUserList: `${mainUrl}/formtype1/assignuserlist`,
    unassignUser: `${mainUrl}/formtype1/unassignuser`,
    selectedFormDetailUserSide: `${mainUrl}/formtype1/selectedfromdetailuserside`,
    selectedFormDetailStat: `${mainUrl}/formtype1/selectedfromdetailstat`,
    selectedFormDetailAnalysis: `${mainUrl}/formtype1/selectedfromdetailanalysis`,
    escalationAddLevel: `${mainUrl}/formtype1/escalationaddlevel`,
    escalationEditLevel: `${mainUrl}/formtype1/escalationeditlevel`,
    escalationDeleteLevel: `${mainUrl}/formtype1/escalationdeletelevel`,
    escalationDeleteUser: `${mainUrl}/formtype1/escalationdeleteuser`,
    escalationAddUser: `${mainUrl}/formtype1/escalationadduser`,
    selectedFormDetailUserSideFilter: `${mainUrl}/formtype1/selectedfromdetailusersidefilter`,
    checkDuplicateSectionName: `${mainUrl}/formtype1/sectionNameCheck`,
    locationList: `${mainUrl}/formtype1/locationlist`,
    formAssignUserList: `${mainUrl}/formtype1/formassignuserlist`,
    formAssignUser: `${mainUrl}/formtype1/formassignuser`,
    deleteFormAssignUser: `${mainUrl}/formtype1/deleteformassignuser`,
    todaysForm: `${mainUrl}/formtype1/todaysform`,
    titleValidate: `${mainUrl}/formtype1/titlevalidation`,
    categoryValidate: `${mainUrl}/formtype1/categoryvalidation`,
    publishedTemplateList: `${mainUrl}/formtype1/publishedtemplatelist`,
    allSchedule: `${mainUrl}/formtype1/alluserschedulelist`,
    workPermit: `${mainUrl}/formtype1/workpermit`,
    workPermitUse: `${mainUrl}/formtype1/workpermituse`,
    workPermitList: `${mainUrl}/formtype1/workpermitlist`,
    workPermitApprove: `${mainUrl}/formtype1/workpermitapprove`,
    workPermitClose: `${mainUrl}/formtype1/workpermitclose`,
    workPermitDecline: `${mainUrl}/formtype1/workpermitdecline`,
    workPermitExtend: `${mainUrl}/formtype1/workpermitextend`,
    workPermitUseDelete: `${mainUrl}/formtype1/workpermitusedelete`,
    workPermitEdit: `${mainUrl}/formtype1/workpermitedit`,
    workPermitAssignEdit: `${mainUrl}/formtype1/assignedworkpermitedit`,
    archivedWorkPermitList: `${mainUrl}/formtype1/archivedworkpermitlist`,
    changeFrequencyType: `${mainUrl}/formtype1/changefrequencytype`,
    surveyUse: `${mainUrl}/formtype1/surveyuse`,
    surveyList: `${mainUrl}/formtype1/surveylist`,
    changeDuration: `${mainUrl}/formtype1/changeduration`,
    monthwisecount: `${mainUrl}/formtype1/monthwisecount`,
    extendedWorkPermitList: `${mainUrl}/formtype1/extendedworkpermitlist`,
    useAsTemplate: `${mainUrl}/formtype1/useastemplate`,
    publishedListFilter: `${mainUrl}/formtype1/publishedlistfilter`,
    unPublishedListFilter: `${mainUrl}/formtype1/unpublishedlistfilter`,
    marketPlaceFilter: `${mainUrl}/formtype1/marketplacefilter`,
    scheduleNotification: `${mainUrl}/formtype1/schedulenotification`,
    formStatusChange: `${mainUrl}/formtype1/formstatuschange`,
    addQuestion: `${mainUrl}/formtype1/addquestion`,
    addSection: `${mainUrl}/formtype1/addsection`,
    createBasicForm: `${mainUrl}/formtype1/createbasicform`,
    editBasicForm: `${mainUrl}/formtype1/editbasicform`,
    deleteQuestion: `${mainUrl}/formtype1/deletequestion`,
    deleteSection: `${mainUrl}/formtype1/deletesection`,
    surveyDelete: `${mainUrl}/formtype1/surveydelete`,
    archivedSurveyList: `${mainUrl}/formtype1/archivedsurveylist`,
    surveyListTotalResponse: `${mainUrl}/formtype1/surveylisttotalresponse`,
    assignElearningUsers: `${mainUrl}/formtype1/assignelearningusers`,
    elearningAssignUsers: `${mainUrl}/formtype1/elearningassignuserlist`,
    deleteElearnAssignUser: `${mainUrl}/formtype1/deleteelearnassignuser`,
    elearningList: `${mainUrl}/formtype1/elearninglist`,
    eLearningAssignUserInfo: `${mainUrl}/formtype1/euserinfo`,
    addElearningStartDate: `${mainUrl}/formtype1/addelearningstartdate`,
    getChangeInprogressStatus: `${mainUrl}/formtype1/getchangeinprogressstatus`,
    categoryWiseFilter: `${mainUrl}/formtype1/categorywisefilter`,
    categoryWiseFilterCount: `${mainUrl}/formtype1/categorywisefiltercount`,
    allTabCategorywiseFilter: `${mainUrl}/formtype1/alltabcategorywisefilter`,
    categoryWiseSurveyFilter: `${mainUrl}/formtype1/categorywisesurveyfilter`,
    getElearningData: `${mainUrl}/formtype1/getelearningdata`,
    elearnStatusChange: `${mainUrl}/formtype1/elearnstatuschange`,
    updateLastTime: `${mainUrl}/formtype1/updatelasttime`,
    getLastAccessTime: `${mainUrl}/formtype1/getlastaccesstime`,
    getElearnScore: `${mainUrl}/formtype1/getelearnscore`,
    getDataCollectionDetail: `${mainUrl}/formtype1/getdatacollectionbyid`,
    getSurveyData: `${mainUrl}/formtype1/getsurveydata`,
    workPermitListClosed: `${mainUrl}/formtype1/workpermitlistclosed`,
    checkContractorRight: `${mainUrl}/formtype1/checkcontractorright`,
    assAssignUserList: `${mainUrl}/formtype1/assfbackassignuserlist`,
    addAssFbackRights: `${mainUrl}/formtype1/addassfbackrights`,
    deleteAssFbackRights: `${mainUrl}/formtype1/deleteassfbackrights`,
    assessmentFbackRights: `${mainUrl}/formtype1/assessmentfbackrights`,
    workPermitListScheduleCount: `${mainUrl}/formtype1/workpermitlistschedulecount`,
    archivedWorkpPrmitCount: `${mainUrl}/formtype1/archivedworkpermitcount`,
    createbasicformfromexcel: `${mainUrl}/formtype1/createbasicformfromexcel`,
  },
  formStat: {
    moduleDashboard: `${mainUrl}/formstat/moduledashboard`,
    moduleDashboardFilter: `${mainUrl}/formstat/moduledashboardfilter`,
    safetyHeadDashboardProjectFormFilter: `${mainUrl}/formtype1/formproject`,
    formTypeDashboard: `${mainUrl}/formstat/formtypedashboard`,
  },
  nmModule: {
    nmDetail: `${mainUrl}/listnm/nearmissdetail`,
    nmdashboard: `${mainUrl}/listnm/nmwebdashboardprojectwise`,

    nmTracker: `${mainUrl}/listnm/trackerlist`,
    nmHeaderCount: `${mainUrl}/listnm/nmheadercounts`,
    nmList: `${mainUrl}/listnm/nmkanbanview`,
    nmEdit: `${mainUrl}/listnm/nmedit`,
    nmProgressList: `${mainUrl}/listnm/inprogresslist`,
    nmPendingList: `${mainUrl}/listnm/pendinglist`,
    nmCompletedList: `${mainUrl}/listnm/completedlist`,
    nmworkflowformsetting: `${mainUrl}/listnm/nmworkflowformsetting`,
    nmsavedformsetting: `${mainUrl}/listnm/nmsavedformsetting`,
    iraTeammemberlist: `${mainUrl}/listira/teammemberlistforaddition`,
    trackerfilter: `${mainUrl}/listnm/trackerfilter`,
    pendingfilter: `${mainUrl}/listnm/pendingfilter`,
    inprogressfilter: `${mainUrl}/listnm/inprogressfilter`,
    completedfilter: `${mainUrl}/listnm/completedfilter`,
    nmlistexport: `${mainUrl}/listnm/nmlistexportoverall`,
    nmtrackerlistexport: `${mainUrl}/listnm/nmtrackerexportoverall`,
    serviceandseverityfilter: `${mainUrl}/listnm/serviceandseverityfilter`,
    projectandseverityfilter: `${mainUrl}/listnm/projectandseverityfilter`,
    monthwisereportfilter: `${mainUrl}/listnm/monthwisereportfilter`,
    deleteNMReport: `${mainUrl}/listnm/deletereportednm`,
    commentList: `${mainUrl}/listnm/nmcommentlist`,
    nmDaysCount: `${mainUrl}/listnm/nmdayscount`,
    deleteIncidentPerson: `${mainUrl}/listnm/deletenmincidentperson`,
    nmCalenderSort: `${mainUrl}/listnm/nmcalendersort`,
  },
  iraModule: {
    iraDetails: `${mainUrl}/listira/iradetails`,
    iraEdit: `${mainUrl}/listira/iraedit`,
    workingShiftMasterList: `${mainUrl}/listira/iraworkingshift`,
    workingTypeMasterList: `${mainUrl}/listira/iraworktype`,
    iraDashboard: `${mainUrl}/listira/irawebdashboardprojectwise`,
    iraheaderCounts: `${mainUrl}/listira/iraheadercounts`,
    iratrackerlist: `${mainUrl}/listira/trackerlist`,
    irakanbanview: `${mainUrl}/listira/irakanbanview`,
    iraPending: `${mainUrl}/listira/pendinglist`,
    iraInprogress: `${mainUrl}/listira/inprogresslist`,
    iraCompleted: `${mainUrl}/listira/completedlist`,
    deleteIRAReport: `${mainUrl}/listira/deletereportedira`,
    iraworkflowformsetting: `${mainUrl}/listira/iraworkflowformsetting`,
    irasavedformsetting: `${mainUrl}/listira/irasavedformsetting`,
    trackerfilterira: `${mainUrl}/listira/trackerfilter`,
    iraTeammemberlist: `${mainUrl}/listira/teammemberlistforaddition`,
    iralistfilter: `${mainUrl}/listira/iralistfilter`,
    iralistexport: `${mainUrl}/listira/iralistexportoverall`,
    iratrackerlistexport: `${mainUrl}/listira/iratrackerexportoverall`,
    serviceandincidenttypefilter: `${mainUrl}/listira/serviceandincidenttypefilter`,
    projectandincidenttypefilter: `${mainUrl}/listira/projectandincidenttypefilter`,
    activityandincidenttypefilter: `${mainUrl}/listira/activityandincidenttypefilter`,
    incidenttimeandincidenttypefilter: `${mainUrl}/listira/incidenttimeandincidenttypefilter`,
    deleteIncidentPerson: `${mainUrl}/listira/deleteincidentperson`,
    iraDaysCount: `${mainUrl}/listira/iradayscount`,
  },
  serviceList: `${mainUrl}/service/servicelist`,
  projectList: `${mainUrl}/service/projectlist`,
  userServiceProject: `${mainUrl}/service/userserviceproject`,
  userDeleteAlternateUserList: `${mainUrl}/service/userdeleteinfo`,
  projectListForm: `${mainUrl}/service/projectlistform`,
  zoneContractorFlag: `${mainUrl}/service/checkavailaibilty`,
  activitylist: `${mainUrl}/master/activitylist`,
  externalactivitylist: `${mainUrl}/master/externalactivitylist`,
  formCategoryList: `${mainUrl}/master/formcategorylist`,
  //formCategoryList: `${mainUrl}/master/formcategorylistadmin`,
  departmentList: `${mainUrl}/master/departmentlist`,
  externalDepartmentList: `${mainUrl}/master/externaldepartmentlist`,
  contractorList: `${mainUrl}/master/contractorlist`,
  externalContractorList: `${mainUrl}/master/externalcontractorlist`,
  zoneList: `${mainUrl}/master/zonelist`,
  externalZoneList: `${mainUrl}/master/externalzonelist`,
  hazardList: `${mainUrl}/master/hazardlist`,
  externalHazardList: `${mainUrl}/master/externalhazardlist`,
  subhazardList: `${mainUrl}/master/subhazardlist`,
  externalSubhazardList: `${mainUrl}/master/externalsubhazardlist`,
  barrierList: `${mainUrl}/master/barrierlist`,
  externalbarrierList: `${mainUrl}/master/externalbarrierlist`,
  locationList: `${mainUrl}/master/locationlist`,
  externalLocationList: `${mainUrl}/master/externallocationlist`,
  closingRightUserList: `${mainUrl}/master/closingrightuserlist`,
  externalClosingRightUserList: `${mainUrl}/master/externalclosingrightuserlist`,
  closingRightUserListPsh: `${mainUrl}/master/closingrightuserlistpsh`,
  UserList: `${mainUrl}/master/userlist`,
  controlMeasureList: `${mainUrl}/master/controlmeasurelist`,
  externalControlMeasureList: `${mainUrl}/master/externalcontrolmeasurelist`,
  sourceList: `${mainUrl}/master/sourcelist`,
  externalSourceList: `${mainUrl}/master/externalsourcelist`,
  reportSetting: {
    formParameter: `${mainUrl}/reortSetting/formParameter`,
    externalFormParameter: `${mainUrl}/reortSetting/externalformParameter`,
    checkStorage: `${mainUrl}/reortSetting/checkStorage`,
  },
  createReport: {
    soiReport: `${mainUrl}/listSoi/soireport`,
    nmReport: `${mainUrl}/listnm/nmreport`,
    iraReport: `${mainUrl}/listira/report`,
    externalSoiReport: `${mainUrl}/listSoi/externalsoireport`,
    externalNmReport: `${mainUrl}/listnm/externalnmreport`,
    externalIraReport: `${mainUrl}/listira/externalreport`,
  },
  adminUser: {
    allUserNumber: `${mainUrl}/adminuser/allusernumber`,
    alluserList: `${mainUrl}/adminuser/alluserlist`,
    safetyheadList: `${mainUrl}/adminuser/safetyhead`,
    addSafetyHead: `${mainUrl}/adminuser/safetyheadd`,
    deleteSafetyHead: `${mainUrl}/adminuser/safetyheaddelete`,
    userStatusChange: `${mainUrl}/adminuser/userchangestatus`,
    userCSVUpload: `${mainUrl}/adminuser/usermasteraddcsv`,
    projectHeadList: `${mainUrl}/adminuser/phuser`,
    addProjectHead: `${mainUrl}/adminuser/phadd`,
    deleteProjectHead: `${mainUrl}/adminuser/phdelete`,
    corporateUserList: `${mainUrl}/adminuser/corporateuser`,
    addCorporateUser: `${mainUrl}/adminuser/cuadd`,
    deleteCorporateUser: `${mainUrl}/adminuser/cudelete`,
    observerList: `${mainUrl}/adminuser/obuser`,
    observerDelete: `${mainUrl}/adminuser/obdelete`,
    observerAdd: `${mainUrl}/adminuser/obadd`,
    reportingUserList: `${mainUrl}/adminuser/ruuser`,
    reportingUserDelete: `${mainUrl}/adminuser/rudelete`,
    reportingUserAdd: `${mainUrl}/adminuser/ruadd`,
    plantSafHeadList: `${mainUrl}/adminuser/pshuser`,
    plantSafetyHeadAdd: `${mainUrl}/adminuser/pshadd`,
    plantSafetyHeadDelete: `${mainUrl}/adminuser/pshdelete`,
    contractorList: `${mainUrl}/admincon/list`,
    departmentList: `${mainUrl}/admindept/list`,

    changeUserField: `${mainUrl}/adminuser/changeuserfield`,
  },
  adminDepartment: {
    adminDepartmentList: `${mainUrl}/admindept/list`,
    searchDeparmentList:`${mainUrl}/adminservice/projectdeptlistsearch`,
    addDepartment: `${mainUrl}/admindept/add`,
    editDepartment: `${mainUrl}/admindept/editname`,
    activateDepartment: `${mainUrl}/admindept/activate`,
    deleteDepartment: `${mainUrl}/admindept/delete`,
    headList: `${mainUrl}/admindept/userlist`,
    csvUpload: `${mainUrl}/admindept/deptmasteraddcsv`,
    
    removeHead: `${mainUrl}/admindept/removehead`,
  },
  adminContractor: {
    contractorList: `${mainUrl}/admincon/list`,
    addContractor: `${mainUrl}/admincon/add`,
    editContractor: `${mainUrl}/admincon/editname`,
    deleteContractor: `${mainUrl}/admincon/delete`,
    activateContractor: `${mainUrl}/admincon/activate`,
    headList: `${mainUrl}/admincon/userlist`,
    csvUpload: `${mainUrl}/admincon/conmasteraddcsv`,
    removeHead: `${mainUrl}/admincon/removehead`,
  },
  adminModule: {
    moduleList: `${mainUrl}/adminmodule/modulelist`,
  },
  aiModel: {
    aiModelList: `${mainUrl}/adminaimodel/aimodelList`,
  },
  adminHira: {
    allNumber: `${mainUrl}/adminhirastat/num`,
    activity: {
      activityList: `${mainUrl}/adminhiraact/list`,
      activityAdd: `${mainUrl}/adminhiraact/add`,
      activityEdit: `${mainUrl}/adminhiraact/edit`,
      activityDelete: `${mainUrl}/adminhiraact/delete`,
      activityActivate: `${mainUrl}/adminhiraact/activate`,
      activityImportList: `${superadminAPI}/export/activitylist`,
      addBulkActivity: `${mainUrl}/adminhiraact/exportlistadd`,
      csvUpload: `${mainUrl}/adminhiraact/addcsv`,
      safetyStandardList: `${superadminAPI}/safetystd/safetystdmasterlist`,
      safetySubStandardList: `${superadminAPI}/safetystd/subsafetystdmasterlist`,
      industryList: `${superadminAPI}/superadmin/industryList`,
      hazardDelete: `${mainUrl}/adminhiraact/deletehazardid`,
      controlMeasureDelete: `${mainUrl}/adminhiraact/deletecontrolmeasure`,
    },

    hazard: {
      hazardList: `${mainUrl}/adminhirahaz/list`,
      hazardAdd: `${mainUrl}/adminhirahaz/add`,
      hazardEdit: `${mainUrl}/adminhirahaz/edit`,
      hazardDelete: `${mainUrl}/adminhirahaz/delete`,
      hazardActivate: `${mainUrl}/adminhirahaz/activate`,
      csvUpload: `${mainUrl}/adminhirahaz/addcsv`,
      exportList: `${superadminAPI}/export/hazardlist`,
      addExportList: `${mainUrl}/adminhirahaz/hazardexportlistadd`,
    },
    subHazard: {
      subHazardList: `${mainUrl}/adminhirasubhaz/list`,
      subHazardAdd: `${mainUrl}/adminhirasubhaz/add`,
      subHazardEdit: `${mainUrl}/adminhirasubhaz/edit`,
      subHazardDelete: `${mainUrl}/adminhirasubhaz/delete`,
    },
    barrier: {
      barrierList: `${mainUrl}/adminhirabar/list`,
      barrierAdd: `${mainUrl}/adminhirabar/add`,
      barrierEdit: `${mainUrl}/adminhirabar/edit`,
      barrierDelete: `${mainUrl}/adminhirabar/delete`,
      barrierActivate: `${mainUrl}/adminhirabar/activate`,
      csvUpload: `${mainUrl}/adminhirabar/addcsv`,
      exportList: `${superadminAPI}/export/barrierlist`,
      addExportList: `${mainUrl}/adminhirabar/barrierexportlistadd`,
    },
  },

  adminCategory: {
    categoryList: `${mainUrl}/master/formcategorylist`,
    formCategoryListAdmin: `${mainUrl}/master/formcategorylistadmin`,
    addcategory: `${mainUrl}/master/addformcategory`,
    editcategory: `${mainUrl}/master/editformcategory`,
    deletecategory: `${mainUrl}/master/deleteformcategory`,
    activateCategory: `${mainUrl}/master/activate`,
  },

  adminDesignation: {
    designationList: `${mainUrl}/admindesign/list`,
    searchdesignationList: `${mainUrl}/admindesign/searchdesignation`,
    designationAdd: `${mainUrl}/admindesign/add`,
    designationeEdit: `${mainUrl}/admindesign/edit`,
    designationDelete: `${mainUrl}/admindesign/delete`,
    csvupload: `${mainUrl}/admindesign/designmasteraddcsv`,
    designationRestore: `${mainUrl}/admindesign/reactivedesignation`,
  },
  adminSource: {
    sourceList: `${mainUrl}/adminsourcemaster/list`,
    sourceAdd: `${mainUrl}/adminsourcemaster/add`,
    sourceEdit: `${mainUrl}/adminsourcemaster/edit`,
    sourceDelete: `${mainUrl}/adminsourcemaster/delete`,
    sourceRestore: `${mainUrl}/adminsourcemaster/reactivesource`,
  },

  adminServices: {
    serviceProjectList: `${mainUrl}/adminservice/serviceprojectdropdownlist`,
    serviceList: `${mainUrl}/adminservice/list`,
    projectList: `${mainUrl}/adminservice/projectlist`,
    projectClose: `${mainUrl}/adminservice/adminprojectclose`,
    projectOpen: `${mainUrl}/adminservice/adminprojectopen`,
    addService: `${mainUrl}/adminservice/addservice`,
    editService: `${mainUrl}/adminservice/editservice`,
    deleteService: `${mainUrl}/adminservice/deleteservice`,
    activateService: `${mainUrl}/adminservice/activeservice`,
    removeServiceHead: `${mainUrl}/adminservice/removeservicehead`,
    bscReport: {
      leadingLagging: {
        list: `${mainUrl}/weightage/formleadlagList`,
        targetInputList: `${mainUrl}/weightage/fetchtargetinputlist`,
        update: `${mainUrl}/weightage/updateleadlagtarget`,
      },
      weightage: {
        list: `${mainUrl}/weightage/formweightageList`,
        save: `${mainUrl}/weightage/updateweightage`,
      },
    },
    projects: {
      stateList: `${mainUrl}/adminservice/projectstatelist`,
      cityList: `${mainUrl}/adminservice/projectcitylist`,
      addProject: `${mainUrl}/adminservice/addproject`,
      editProject: `${mainUrl}/adminservice/projectdetailedit`,
      deleteProject: `${mainUrl}/adminservice/deleteproject`,
      activateProject: `${mainUrl}/adminservice/activeproject`,
      editProjectHead: `${mainUrl}/adminservice/projectheadedit`,
      projectDetail: `${mainUrl}/adminservice/projectdetail`,
      projectAIModel:`${mainUrl}/adminservice/projectaimodellist`,
      zoneList: `${mainUrl}/adminservice/projectzonelist`,
      zoneCsv: `${mainUrl}/adminservice/projectzonecsvupload`,
      zoneLocationCsv: `${mainUrl}/adminservice/projectlocationcsvupload`,
      addZone: `${mainUrl}/adminservice/projectaddzone`,
      editZone: `${mainUrl}/adminservice/projecteditzone`,
      deleteZone: `${mainUrl}/adminservice/projectdeletezone`,
      activateZone: `${mainUrl}/adminservice/projectactivezone`,
      removeZoneHead: `${mainUrl}/adminservice/removeprojectzonehead`,
      locationList: `${mainUrl}/adminservice/projectlocationlist`,
      searchLocationList: `${mainUrl}/adminservice/projectlocationlistsearch`,
      addLocation: `${mainUrl}/adminservice/projectaddlocation`,
      editLocation: `${mainUrl}/adminservice/projecteditlocation`,
      deleteLocation: `${mainUrl}/adminservice/projectdeletelocation`,
      activateLocation: `${mainUrl}/adminservice/projectactivelocation`,
      removeLocationHead: `${mainUrl}/adminservice/removeprojectzonelocationhead`,
      departmentList: `${mainUrl}/adminservice/projectdeptlist`,
      searchdepartmentList: `${mainUrl}/adminservice/projectdeptlistsearch`,
      contractorList: `${mainUrl}/adminservice/projectconlist`,
      addDepartment: `${mainUrl}/adminservice/projectadddept`,
      editDepartment: `${mainUrl}/adminservice/projecteditdept`,
      deleteDepartment: `${mainUrl}/adminservice/projectdeletedept`,
      removeDepartmentHead: `${mainUrl}/adminservice/removeprojectdepthead`,
      addContractor: `${mainUrl}/adminservice/projectaddcon`,
      editContractor: `${mainUrl}/adminservice/projecteditcon`,
      deleteContractor: `${mainUrl}/adminservice/projectdeletecon`,
      removeContractorHead: `${mainUrl}/adminservice/removeprojectconhead`,
      addDepartmentList: `${mainUrl}/adminservice/projectdeptlisforaddition`,
      addContractorList: `${mainUrl}/adminservice/projectconlistforadition`,
      userHeadList: `${mainUrl}/adminservice/userlistforaddition`,
      userBulkHeadList: `${mainUrl}/adminservice/fetchuserforbulkassign`,
      projectSpace: `${mainUrl}/adminservice/serviceprojectspace`,
      projectRemoveUser: `${mainUrl}/adminservice/projectremoveuser`,
      projectRemoveHead: `${mainUrl}/adminservice/removeprojecthead`,
      hazardListByActivity: `${mainUrl}/adminservice/hazardlist`,
      generateQr: `${mainUrl}/adminservice/generateqr`,
      editQr: `${mainUrl}/adminservice/editqr`,
      validateQr: `${mainUrl}/adminservice/validateqrurl`,
    },
    modules: {
      moduleList: `${mainUrl}/adminservice/projectmodulelist`,
      activateModule: `${mainUrl}/adminservice/projectaddmodule`,
      deactivateModule: `${mainUrl}/adminservice/projectremovemodule`,
      activateAIModel: `${mainUrl}/adminservice/projectaddaimodel`,
      deactivateAIModel: `${mainUrl}/adminservice/projectremoveaimodel`,
      escalationLevelList: `${mainUrl}/adminservice/projectescalationlist`,
      addEscalationLevel: `${mainUrl}/adminservice/projectescalationaddlevel`,
      editEscalationLevel: `${mainUrl}/adminservice/projectescalationeditlevel`,
      deleteEscalationLevel: `${mainUrl}/adminservice/projectescalationdeletelevel`,
      addonUserListForLevel: `${mainUrl}/adminservice/projectescalationleveluserlistforaddition`,
      addUserForLevel: `${mainUrl}/adminservice/projectescalationleveladduser`,
      removeUserForLevel: `${mainUrl}/adminservice/projectescalationlevelremoveuser`,
      reportListNumber: `${mainUrl}/adminservice/projectreportnumber`,
      reportList: `${mainUrl}/adminservice/projectreportlist`,
      addReportUser: `${mainUrl}/adminservice/projectreportadduser`,
      removeReportUser: `${mainUrl}/adminservice/projectreportremoveuser`,
      addonReportUserList: `${mainUrl}/adminservice/projectreportuserlistforaddition`,
      closingRightList: `${mainUrl}/adminservice/projectcrlist`,
      closingRightPaginateDataList: `${mainUrl}/adminservice/projectcrlisttypewise`,
      addClosingRight: `${mainUrl}/adminservice/projectcrinitialresponse`,
      removeClosingRight: `${mainUrl}/adminservice/projectcrparameterdelete`,
      addonUserClosingRight: `${mainUrl}/adminservice/projectcruserlistforadd`,
      addUserClosingRight: `${mainUrl}/adminservice/projectcradduser`,
      removeUserClosingRight: `${mainUrl}/adminservice/projectcrremoveuser`,
      projectContractorStatus: `${mainUrl}/adminservice/adminprojectconstatus`,
    },
    users: {
      projectUserNumber: `${mainUrl}/adminservice/projectusernumber`,
      searchUserList: `${mainUrl}/adminservice/searchadminuserlist`,
      observerList: `${mainUrl}/adminservice/projectuserlistob`,
      projectHeadList: `${mainUrl}/adminservice/projectuserlistph`,
      corporateList: `${mainUrl}/adminservice/projectuserlistcu`,
      safetyHeadList: `${mainUrl}/adminservice/projectuserlistsh`,
      safetyTeamCompanyList: `${mainUrl}/adminservice/projectuserliststeamcu`,
      safetyTeamContractorList: `${mainUrl}/adminservice/projectuserliststeamch`,
      addUserFromList: `${mainUrl}/adminservice/projectadduserfromlist`,
      addBulkUserFromList: `${mainUrl}/adminservice/bulkUserListAdd`,
      addContractorFromList: `${mainUrl}/adminservice/projectaddcontractortosafetyteam`,
      contractorUserList: `${mainUrl}/adminservice/projectcontractorlistforsafetyteam`,
      userListForAdd: `${mainUrl}/adminservice/projectuserlistforaddition`,
      userCategoryList: `${mainUrl}/adminservice/projectusercategorylist`,
      userCategoryAdd: `${mainUrl}/adminservice/projectusercategoryadd`,
      plantSafetyHeadList: `${mainUrl}/adminservice/projectuserlistpsh`,
      reportingUserList: `${mainUrl}/adminservice/projectuserlistru`,
    },
    hira: {
      activityList: `${mainUrl}/adminservice/projectactlist`,
      activityListForAdd: `${mainUrl}/adminservice/projectactlistforaddition`,
      addActivity: `${mainUrl}/adminservice/projectactadd`,
      deleteActivity: `${mainUrl}/adminservice/projectactremove`,
      barrierList: `${mainUrl}/adminservice/projectbarlist`,
      barrierListForAdd: `${mainUrl}/adminservice/projectbarlistforaddition`,
      addBarrier: `${mainUrl}/adminservice/projectbaradd`,
      deleteBarrier: `${mainUrl}/adminservice/projectbarremove`,
      hazardListForAdd: `${mainUrl}/adminservice/projecthazlistforaddition`,
      hazardList: `${mainUrl}/adminservice/projecthazlist`,
      addHazard: `${mainUrl}/adminservice/projecthazadd`,
      deleteHazard: `${mainUrl}/adminservice/projecthazremove`,
      subHazardList: `${mainUrl}/adminservice/projectsubhazlist`,
    },
  },
  adminDashboard: {
    list: `${mainUrl}/admindashboard/list`,
    map: `${mainUrl}/adminmap/list`,
  },
  adminProfile: {
    adminProfileDetail: `${mainUrl}/adminprofile/list`,
    companyProfileDetail: `${mainUrl}/admincompany/list`,
    companyProfileEdit: `${mainUrl}/admincompany/edit`,
  },
  adminSpace: {
    spaceDetail: `${mainUrl}/adminspace/space`,
  },
  adminMaster: {
    contributingFactorList: `${mainUrl}/adminmodule/contributingFactorList`,
    contributingFactorAdd: `${mainUrl}/adminmodule/contributingFactorAdd`,
    contributingFactorEdit: `${mainUrl}/adminmodule/contributingFactorEdit`,
    contributingFactorDelete: `${mainUrl}/adminmodule/contributingFactorDelete`,
    contributingFactorActivate: `${mainUrl}/adminmodule/contributingFactorActive`,
    importContributingFactor: `${mainUrl}/adminfactor/addcontributingfactorlist`,
    exportListCF: `${superadminAPI}/export/contributingfactorlist`,
    addExportListCF: `${mainUrl}/adminfactor/addcontributingfactorlist`,

    immediateFactorList: `${mainUrl}/adminmodule/immediateFactorList`,
    immediateFactorAdd: `${mainUrl}/adminmodule/immediateFactorAdd`,
    immediateFactorEdit: `${mainUrl}/adminmodule/immediateFactorEdit`,
    immediateFactorDelete: `${mainUrl}/adminmodule/immediateFactorDelete`,
    immediateFactorActive: `${mainUrl}/adminmodule/immediateFactorActive`,
    importImmediateFactor: `${mainUrl}/adminfactor/addimmediatefactorlist`,
    exportListIF: `${superadminAPI}/export/immediatefactorlist`,
    addExportListIF: `${mainUrl}/adminfactor/addimmediatefactorlist`,

    rootCauseFactorList: `${mainUrl}/adminmodule/rootcauseanalysisfactorList`,
    rootCauseFactorEdit: `${mainUrl}/adminmodule/rootcauseanalysisfactoredit`,
    rootCauseFactorAdd: `${mainUrl}/adminmodule/rootcauseanalysisfactoradd`,
    rootCauseFactorDelete: `${mainUrl}/adminmodule/rootcauseanalysisfactordelete`,
    rootCauseFactorActive: `${mainUrl}/adminmodule/rootcauseanalysisfactorActive`,
    importRootCauseFactor: `${mainUrl}/adminfactor/addrootcausefactorlist`,
    exportListRCF: `${superadminAPI}/export/rootcausefactorlist`,
    addExportListRCF: `${mainUrl}/adminfactor/addrootcausefactorlist`,

    categoryList: `${mainUrl}/adminmodule/categoryList`,
    categoryAdd: `${mainUrl}/adminmodule/categoryAdd`,
    categoryEdit: `${mainUrl}/adminmodule/categoryEdit`,
    categoryDelete: `${mainUrl}/adminmodule/categorydelete`,
    categoryActive: `${mainUrl}/adminmodule/categoryActive`,
    importCategory: `${mainUrl}/categoryfactor/addcategorymasterlist`,
    exportListCategory: `${superadminAPI}/export/categorymasterslist`,
    addExportListCategory: `${mainUrl}/categoryfactor/addcategorymasterlist`,
    adminCategoryList: `${mainUrl}/adminmodule/admincategoryList`,
  },
  adminPerformanceSetting: {
    getSettingData: `${mainUrl}/topperformer/tpdata`,
    updateSettingData: `${mainUrl}/topperformer/updateTpweightage`,
  },
  help: {
    helpList: `${mainUrl}/help/helplist`,
    helpUserList: `${mainUrl}/help/helpuserslist`,
    helpModuleList: `${mainUrl}/help/helpmoduleslist`,
  },
  tbt: {
    activityList: `${mainUrl}/tbt/activitytbtlist`,
    updateViewCount:`${mainUrl}/tbt/documnetViewCount`,
    addDocument: `${mainUrl}/tbt/adddocument`,
    documentList: `${mainUrl}/tbt/documentlist`,
    policyDocumentList: `${mainUrl}/tbt/tbtactivityanddocumentlist`,
    deleteDocument: `${mainUrl}/tbt/deletedocument`,
    serviceProjectDocument: `${mainUrl}/tbt/serviceprojectdocument`,
    getSelectedServiceProject: `${mainUrl}/tbt/getselectedserviceproject`,
    serviceProjectMsdsDocument: `${mainUrl}/tbt/serviceprojectmsdsdocument`,
    fetchSelectedProjectActivity: `${mainUrl}/tbt/fetchselectedserviceprojectactivity`,
    fetchSelectedProjectDocument: `${mainUrl}/tbt/fetchselectedserviceprojectdocument`,
  },
  goodPractices: {
    deleteGP: `${mainUrl}/listgoodacts/deletega`,
    gasavedformsetting: `${mainUrl}/project/gaformparameterlist`,
    gaupdatesavedformsetting: `${mainUrl}/project/gaformparameteredit`,
    gpMasterList: `${mainUrl}/gamaster/list`,
    gpMasterAdd: `${mainUrl}/gamaster/add`,
    gpMasterEdit: `${mainUrl}/gamaster/edit`,
    gpMasterDelete: `${mainUrl}/gamaster/delete`,
    gpMasterActivate: `${mainUrl}/gamaster/reactivate`,
    addGoodPractice: `${mainUrl}/listgoodacts/goodactsreport`,
    externalAddGoodPractice: `${mainUrl}/listgoodacts/externalgoodactsreport`,
    categoryList: `${mainUrl}/listgoodacts/categorymasterslist`,
    deleteGooPractice: `${mainUrl}/listgoodacts/deletega`,
    gooPracticesList: `${mainUrl}/listgoodacts/goodactslist`,
    goodPractiseCount: `${mainUrl}/listgoodacts/goodactcount`,
    goodPracticesEdit: `${mainUrl}/listgoodacts/goodactsedit`,
    goodPractiseFilter: `${mainUrl}/listgoodacts/goodactfilter`,
    gpgraph: `${mainUrl}/listgoodacts/gagraph`,
    gpDetailById: `${mainUrl}/listgoodacts/gadetailbyid`,
  },
  training: {
    trainingReport: `${mainUrl}/training/trainingreport`,
    trainingList: `${mainUrl}/training/traininglist`,
    deleteTraining: `${mainUrl}/training/deletetraining`,
    trainingNumbers: `${mainUrl}/training/trainingnumbers`,
    trainingtbtgendergraph: `${mainUrl}/training/trainingtbtgendergraph`,
    tbtNumbers: `${mainUrl}/training/tbtnumbers`,
    trainingEdit: `${mainUrl}/training/trainingTbtEdit`,
    trainingFilter: `${mainUrl}/training/trainingfilter`,
    updateStatus: `${mainUrl}/training/updatetrainingstatus`,
    getTrainingById: `${mainUrl}/training/gettrainingbyid`,
    getAssignPretest: `${mainUrl}/training/assignpretestlist`,
    getTrainingAssignUserList: `${mainUrl}/training/trainingassignuserlist`,
    addTrainingRights: `${mainUrl}/training/addtrainingrights`,
    deleteTrainingRights: `${mainUrl}/training/deletetrainingrights`,
    assignTrainingUser: `${mainUrl}/training/assigntraininguser`,
    trainingCount: `${mainUrl}/training/trainingcount`,
    trainingListCalSort: `${mainUrl}/training/traininglistcalsort`,
  },
  iraMaster: {
    stepOne: {
      actionList: `${mainUrl}/irastep1/list`,
      actionAdd: `${mainUrl}/irastep1/add`,
      actionDelete: `${mainUrl}/irastep1/delete`,
      actionReactive: `${mainUrl}/irastep1/reactive`,
      actionImportList: `${superadminAPI}/export/actionparameterlist`,
      actionAddImport: `${mainUrl}/irastep1/actionparameterexportlistadd`,
      actionEdit: `${mainUrl}/irastep1/edit`,
    },
    stepTwo: {
      evidence: {
        evidenceList: `${mainUrl}/irastep2/listevidencecategory`,
        evidenceAdd: `${mainUrl}/irastep2/addevidencecategory`,
        evidenceDelete: `${mainUrl}/irastep2/deleteevidencecategory`,
        evidenceReactive: `${mainUrl}/irastep2/reactiveevidencecategory`,
        evidenceAddImport: `${mainUrl}/irastep2/evidencecategoryexportlistadd`,
        evidenceImportList: `${superadminAPI}/export/evidencecategorylist`,
        evidenceEdit: `${mainUrl}/irastep2/editevidencecategory`,
      },
      workType: {
        workTypeList: `${mainUrl}/irastep2/listworktype`,
        workTypeAdd: `${mainUrl}/irastep2/addworktype`,
        workTypeDelete: `${mainUrl}/irastep2/deleteworktype`,
        workTypeReactive: `${mainUrl}/irastep2/reactiveworktype`,
        workTypeAddImport: `${mainUrl}/irastep2/worktypeexportlistadd`,
        workTypeImportList: `${superadminAPI}/export/worktypelist`,
        editWorkType: `${mainUrl}/irastep2/editworktype`,
      },
      timeIncident: {
        timeIncidentList: `${mainUrl}/irastep2/listtimeofincident`,
        timeIncidentDelete: `${mainUrl}/irastep2/deletetimeofincident`,
        timeIncidentAdd: `${mainUrl}/irastep2/addtimeofincident`,
        timeIncidentReactive: `${mainUrl}/irastep2/reactivetimeofincident`,
        timeIncidentAddImport: `${mainUrl}/irastep2/timeofincidentexportlistadd`,
        timeIncidentImportList: `${superadminAPI}/export/timeofincidentlist`,
        editTimeOfIncident: `${mainUrl}/irastep2/edittimeofincident`,
      },
      injuredPart: {
        injuredPartList: `${mainUrl}/irastep2/listinjuredparts`,
        injuredPartDelete: `${mainUrl}/irastep2/deleteinjuredparts`,
        injuredPartAdd: `${mainUrl}/irastep2/addinjuredparts`,
        injuredPartReactive: `${mainUrl}/irastep2/reactiveinjuredparts`,
        injuredPartAddImport: `${mainUrl}/irastep2/injuredpartsexportlistadd`,
        injuredPartImportList: `${superadminAPI}/export/injuredpartslist`,
        editInjuredParts: `${mainUrl}/irastep2/editinjuredparts`,
      },
      workingShift: {
        workingShiftList: `${mainUrl}/irastep2/listworkingshift`,
        workingShiftAdd: `${mainUrl}/irastep2/addworkingshift`,
        workingShiftDelete: `${mainUrl}/irastep2/deleteworkingshift`,
        workingShiftReactivate: `${mainUrl}/irastep2/reactiveworkingshift`,
        workingShiftAddImport: `${mainUrl}/irastep2/workingshiftexportlistadd`,
        workingShiftImportList: `${superadminAPI}/export/workingshiftlist`,
        editWorkingShift: `${mainUrl}/irastep2/editworkingshift`,
      },
      incidentAnalysisQuestion: {
        questionList: `${mainUrl}/irastep2/listincidentanalysisquestion`,
        questionAdd: `${mainUrl}/irastep2/addincidentanalysisquestion`,
        questionDelete: `${mainUrl}/irastep2/deleteincidentanalysisquestion`,
        questionReactivate: `${mainUrl}/irastep2/reactiveincidentanalysisquestion`,
        questionAddImport: `${mainUrl}/irastep2/incidentanalysisquestionexportlistadd`,
        questionImportList: `${superadminAPI}/export/incidentanalysisquestionlist`,
        questionEdit: `${mainUrl}/irastep2/editincidentanalysisquestion`,
      },

      genderStepTwo: {
        genderListStepTwo: `${mainUrl}/irastep2/iragenderlist`,
      },

      injuryType: {
        injuryTypeList: `${mainUrl}/irastep2/irainjurytypelist`,
        injuryTypeAdd: `${mainUrl}/irastep2/addinjurytype`,
        injuryTypeDelete: `${mainUrl}/irastep2/deleteinjurytype`,
        injuryTypeEdit: `${mainUrl}/irastep2/editinjurytype`,
        injuryTypeRestore: `${mainUrl}/irastep2/reactiveinjureytype`,
      },
    },
    stepThree: {
      otherInfo: {
        list: `${mainUrl}/irastep3/list`,
        add: `${mainUrl}/irastep3/add`,
        delete: `${mainUrl}/irastep3/delete`,
        reactive: `${mainUrl}/irastep3/reactive`,
        addImport: `${mainUrl}/irastep3/otherinfoexportlistadd`,
        importList: `${superadminAPI}/export/otherinfolist`,
        edit: `${mainUrl}/irastep3/edit`,
      },
      returnToWork: {
        returnToWorkList: `${mainUrl}/irastep3/returntoworklist`,
        returnToWorkAdd: `${mainUrl}/irastep3/addreturntoworkinfo`,
        returnToWorkEdit: `${mainUrl}/irastep3/editreturntoworkinfo`,
        returnToWorkDelete: `${mainUrl}/irastep3/deletereturntowork`,
        returnToWorkRestore: `${mainUrl}/irastep3/reactivereturntowork`,
      },
      FiveAnalysis: {
        tabList: `${mainUrl}/irastep3/fivemanalysislist`,
        add: `${mainUrl}/irastep3/addfivemanalysisinfo`,
        delete: `${mainUrl}/irastep3/fivemanalysisdelete`,
        edit: `${mainUrl}/irastep3/fivemanalysisedit`,
        reActive: `${mainUrl}/irastep3/fivemanalysisReactive`,
      },
    },
    stepFour: {
      mainCategory: {
        list: `${mainUrl}/irastep4/maincategorylist`,
        add: `${mainUrl}/irastep4/maincategoryadd`,
        delete: `${mainUrl}/irastep4/maincategorydelete`,
        reactive: `${mainUrl}/irastep4/maincategoryreactive`,
        addImport: `${mainUrl}/irastep4/cfcategoryexportlistadd`,
        importList: `${superadminAPI}/export/cfcategorylist`,
        edit: `${mainUrl}/irastep4/maincategoryedit`,
      },
      subCategory: {
        list: `${mainUrl}/irastep4/subcategorylist`,
        add: `${mainUrl}/irastep4/subcategoryadd`,
        delete: `${mainUrl}/irastep4/subcategorydelete`,
        reactive: `${mainUrl}/irastep4/subcategoryreactive`,
        edit: `${mainUrl}/irastep4/subcategoryedit`,
      },
      majorCategoryUrl: {
        list: `${mainUrl}/irastep4/majorcategorylist`,
        add: `${mainUrl}/irastep4/majorcategoryadd`,
        delete: `${mainUrl}/irastep4/majorcategorydelete`,
        reactive: `${mainUrl}/irastep4/majorcategoryreactive`,
        edit: `${mainUrl}/irastep4/majorcategoryedit`,
      },
      nearCategoryUrl: {
        list: `${mainUrl}/irastep4/nearcategorylist`,
        add: `${mainUrl}/irastep4/nearcategoryadd`,
        delete: `${mainUrl}/irastep4/nearcategorydelete`,
        reactive: `${mainUrl}/irastep4/nearcategoryreactive`,
        edit: `${mainUrl}/irastep4/nearcategoryedit`,
      },
      intermediateCategory: {
        list: `${mainUrl}/irastep4/intermediatecategorylist`,
        add: `${mainUrl}/irastep4/intermediatecategoryadd`,
        delete: `${mainUrl}/irastep4/intermediatecategorydelete`,
        reactive: `${mainUrl}/irastep4/intermediatecategoryreactive`,
        edit: `${mainUrl}/irastep4/intermediatecategoryedit`,
      },
      rootCauseType: {
        list: `${mainUrl}/irastep4/rootcausetypelist`,
        add: `${mainUrl}/irastep4/rctypecategoryadd`,
        delete: `${mainUrl}/irastep4/rctypecategorydelete`,
        reactive: `${mainUrl}/irastep4/rctypecategoryreactive`,
        addImport: `${mainUrl}/irastep4/cfrootcausecategoryexportlistadd`,
        importList: `${superadminAPI}/export/cfrootcausecategorylist`,
        edit: `${mainUrl}/irastep4/rctypecategoryedit`,
      },
      rootCause: {
        list: `${mainUrl}/irastep4/rootcauselist`,
        add: `${mainUrl}/irastep4/rccategoryadd`,
        delete: `${mainUrl}/irastep4/rccategorydelete`,
        reactive: `${mainUrl}/irastep4/rccategoryreactive`,
        edit: `${mainUrl}/irastep4/rccategoryedit`,
      },
    },
    stepFive: {
      category: {
        list: `${mainUrl}/adminmodule/categoryList`,
      },
    },
  },
  reporting: {
    ira: {
      viewreport: `${mainUrl}/listira/iraviewpdf`,
    },
    nm: {
      viewreport: `${mainUrl}/listnm/nmpdfview`,
    },
  },
  safetymanhrs: {
    manhours: `${mainUrl}/safetymanhrs/addeditsafetymanhours`,
    safetyManHoursRightList: `${mainUrl}/safetymanhrs/safetymanhoursrightlist`,
    addSafetyManHoursRights: `${mainUrl}/safetymanhrs/addsafetymanhoursrights`,
    deleteSafetyManHoursRight: `${mainUrl}/safetymanhrs/deletesafetymanhoursrights`,
    checkSafetyManHoursRights: `${mainUrl}/safetymanhrs/checksafetymanhoursrights`,
    monthWiseHoursCount: `${mainUrl}/safetymanhrs/monthwisehourscount`,
    getSafetyManHours: `${mainUrl}/safetymanhrs/getsafetymanhours`,
    editSafeManHoursValue: `${mainUrl}/safetymanhrs/editsafemanhoursvalue`,
    getLocationWiseSafeManHours: `${mainUrl}/safetymanhrs/getlocationwisesafemanhours`,
    getMonthWiseSafeManHoursCount: `${mainUrl}/safetymanhrs/getmonthwisesafemanhourscount`,
    checkSafeManHours: `${mainUrl}/safetymanhrs/checksafemanhours`,
    changesafemanhoursmonth: `${mainUrl}/safetymanhrs/changesafemanhoursmonth`,
  },
  safetyMeeting: {
    addSafetyMeeting: `${mainUrl}/safetymeeting/addsafetymeeting`,
    editSafetyMeeting: `${mainUrl}/safetymeeting/editsafetymeeting`,
    safetyMeetingRightList: `${mainUrl}/safetymeeting/safetymeetingrightlist`,
    safetyMeetingInviteUserList: `${mainUrl}/safetymeeting/safetyMeetinginviteuserlist`,
    addSafetyMeetingRights: `${mainUrl}/safetymeeting/addsafetymeetingrights`,
    deleteSafetyMeetingRights: `${mainUrl}/safetymeeting/deletesafetymeetingrights`,
    checkSafetyMeetingRights: `${mainUrl}/safetymeeting/checksafetymeetingrights`,
    safetyMeetingDetails: `${mainUrl}/safetymeeting/safetymeetingdetails`,
    safetyMeetingList: `${mainUrl}/safetymeeting/safetymeetinglist`,
    // saveSafetyMeetingRecommendation: `${mainUrl}/safetymeeting/savesafetymeetingrecommendation`,
    safetyMeetingRecommendationList: `${mainUrl}/safetymeeting/safetymeetingrecommendationlist`,
    safetyMeetingCount: `${mainUrl}/safetymeeting/safetymeetingcount`,
    safetyMeetingGraph: `${mainUrl}/safetymeeting/safetymeetinggraph`,
    deleteSafetyMeeting: `${mainUrl}/safetymeeting/deletesafetymeeting`,
    safetyMeetingStatusChange: `${mainUrl}/safetymeeting/safetymeetingstatuschange`,
    changeStartDate: `${mainUrl}/safetymeeting/updatestartdate`,
    changeEndDate: `${mainUrl}/safetymeeting/updateenddate`,
    deleteOtherInviteUser: `${mainUrl}/safetymeeting/deleteotherinviteuser`,
    addRecommendation: `${mainUrl}/safetymeeting/saverecommendation`,
    recommendationEdit: `${mainUrl}/safetymeeting/recommendationedit`,
    recommendationDelete: `${mainUrl}/safetymeeting/recommendationdelete`,
    safetyRecTotalCount: `${mainUrl}/safetymeeting/safetydbcount`,
    allOtherUserSmData: `${mainUrl}/safetymeeting/allotherusersafetymeetingdata`,
    allOtherUserSmCount: `${mainUrl}/safetymeeting/allotherusersmcount`,
  },
  polling: {
    pollingReport: `${mainUrl}/polling/pollingreport`,
    pollingPlan: `${mainUrl}/polling/planpollingreport`,
    pollingList: `${mainUrl}/polling/pollingList`,
    editPollingReport: `${mainUrl}/polling/editpollingreport`,
    deletePoll: `${mainUrl}/polling/deletepoll`,
    activePoll: `${mainUrl}/polling/activepoll`,
    savePollResponse: `${mainUrl}/polling/savepollresponse`,
    pollcategorylist: `${mainUrl}/polling/pollcategorylist`,
    pollgraphs: `${mainUrl}/polling/pollgraphs`,
    pollingById: `${mainUrl}/polling/pollingbyid`,
    editPollFile: `${mainUrl}/polling/editpollfile`,
  },
  formTypeGraph: {
    auditRecommendation: `${mainUrl}/task/auditrecommendationlist`,
    graph: `${mainUrl}/formtypegraph/graph`,
    workpermitlistgraph: `${mainUrl}/formtypegraph/workpermitlistgraph`,
    surveygraph: `${mainUrl}/surveygraph/surveylistgraph`,
    formTypeCount: `${mainUrl}/formtypegraph/formtypecount`,
    surveyListCount: `${mainUrl}/formtypegraph/surveylistcount`,
    workPermitCount: `${mainUrl}/formtypegraph/workpermitcount`,
    templateListCount: `${mainUrl}/formtypegraph/templatelistcount`,
    dataCollectionGraph: `${mainUrl}/formtypegraph/datacollectiongraph`,
    bbsgraph: `${mainUrl}/formtypegraph/bbsgraph`,
    formcounts: `${mainUrl}/formtypegraph/formcounts`,
    elearningCount: `${mainUrl}/formtypegraph/elearninglistcount`,
    dbAvgPerCompliance: `${mainUrl}/formtypegraph/dbavgpercompliance`,
  },
  costing: {
    costingList: `${mainUrl}/costing/formcostingList`,
    updateCosting: `${mainUrl}/costing/updatecosting`,
    costingmonthwisecount: `${mainUrl}/costing/costingmonthwisecount`,
  },
  taskManagement: {
    task: `${mainUrl}/task/task`,
    allTaskList: `${mainUrl}/task/alltasklist`,
    // allTaskList: `${mainUrl}/task/alltasklistnew`,
    taskList: `${mainUrl}/task/tasklist`,
    taskReport: `${mainUrl}/task/taskreport`,
    changeStatus: `${mainUrl}/task/changestatus`,
    taskDelete: `${mainUrl}/task/taskdelete`,
    taskGraph: `${mainUrl}/task/taskgraph`,
    taskEdit: `${mainUrl}/task/taskedit`,
    todayTaskData: `${mainUrl}/task/todaytaskdata`,
    recommendationList: `${mainUrl}/task/recommendationlist`,
    taskCommentList: `${mainUrl}/task/taskcommentlist`,
    taskContentAddLikeToComment: `${mainUrl}/task/taskcontentaddliketocomment`,
    taskcontentcommentremoveunlike: `${mainUrl}/task/taskcontentcommentremoveunlike`,
    taskContentAddSubcomment: `${mainUrl}/task/taskcontentaddsubcomment`,
    taskDetailCommentSubcommentlist: `${mainUrl}/task/taskdetailcommentsubcommentlist`,
    taskdeletecomment: `${mainUrl}/task/taskdeletecomment`,
    taskcontentaddcomment: `${mainUrl}/task/taskcontentaddcomment`,
    taskAddDocument: `${mainUrl}/task/taskadddocument`,
    deleteUrlLink: `${mainUrl}/task/deleteurllink`,
    allTaskListCount: `${mainUrl}/task/alltasklistcount`,
  },
  goal: {
    goal: `${mainUrl}/goal/goal`,
    goalReport: `${mainUrl}/goal/goalreport`,
    goalList: `${mainUrl}/goal/goallist`,
    changeStatus: `${mainUrl}/goal/changestatus`,
    goalDelete: `${mainUrl}/goal/goaldelete`,
    inactiveDeletedGoalList: `${mainUrl}/goal/inactivedeletedgoallist`,
    goalGraph: `${mainUrl}/goal/goalgraph`,
    tabsCount: `${mainUrl}/goal/tabscount`,
    goalEdit: `${mainUrl}/goal/goaledit`,
    goalEditTask: `${mainUrl}/goal/goaledittask`,
  },

  external: {
    externalMarketPlace: `${mainUrl}/external/externalmarketplace`,
    externalDocumentList: `${mainUrl}/external/externaldocumentlist`,
    externalmarketplacefilter: `${mainUrl}/external/externalmarketplacefilter`,
    externalUserFormDetails: `${mainUrl}/external/externaluserformdetails`,
    externalUserResponse: `${mainUrl}/external/externaluserresponse`,
    externalSurveyGraph: `${mainUrl}/external/externalsurveygraph`,
    surveyStatus: `${mainUrl}/external/surveystatus`,
    externalMarketplaceKeySearch: `${mainUrl}/external/externalmarketplacefiltersearch`,
  },
};

export default urls;
